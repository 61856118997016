@import url("https://fonts.googleapis.com/css2?family=Faster+One&family=Oswald:wght@200..700&family=Playwrite+NG+Modern:wght@100..400&display=swap");

body {
  font-family: "Oswald", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

form {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-height: 500px; /* Ensure consistent height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

label {
  display: inline-block;
  margin-bottom: 8px;
  font-weight: bold;
}

input[type="text"],
input[type="email"],
input[type="number"],
select,
textarea {
  width: calc(100% - 30px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
}

textarea {
  height: 16rem;
  width: 100%;
}

input[type="file"] {
  margin-bottom: 10px;
}

button,
input[type="submit"] {
  background-color: #074f80;
  border-radius: 4px;

  margin-bottom: 1rem;
  width: 12rem;
  height: 4rem;
  font-family: "Oswald", sans-serif;
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase;
  font-size: 1.5rem;
  color: white;
  border: solid rgba(7, 79, 128, 0.5) 3px;
  transition: 0.3s;
}

button:hover,
input[type="submit"]:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  font-size: 0.8em;
  margin-left: 10px;
  display: inline;
}

/* Form page styles */
.formPage1,
.formPage2 {
  display: none;
}

.formPage1.active,
.formPage2.active {
  display: block;
}

/* Responsive styles */
@media (max-width: 600px) {
  form {
    padding: 10px;
  }
}

/* File size display */
.file-size {
  font-size: 0.8em;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

.formButtons {
  background-color: #074f80;
  border-radius: 4px;

  margin-bottom: 1rem;
  width: 12rem;
  height: 4rem;
  font-family: "Oswald", sans-serif;
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase;
  font-size: 1.5rem;
  color: rgba(136, 207, 242, 0.7);
  border: solid rgba(7, 79, 128, 0.5) 3px;
  transition: 0.3s;
}

@media (min-width: 601px) {
  input[type="submit"] {
    margin-left: 22px;
  }

  input[type="submit"]:hover {
    color: #074f80;
    background-color: rgba(136, 207, 242, 0.7);
    border: solid rgba(7, 79, 128, 0.5) 3px;
    transition: 0.3s;
    text-shadow: -1px -1px 10px rgba(360, 360, 360, 0.3);
  }
}
