@import url("https://fonts.googleapis.com/css2?family=Faster+One&family=Oswald:wght@200..700&family=Playwrite+NG+Modern:wght@100..400&display=swap");

.scroll-down {
  font-family: "Oswald", sans-serif;
  font-size: 6vh;
  position: fixed;
  bottom: 0;
  height: 10%;
  width: 100%;
  text-align: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: rgba(360, 360, 360, 0.8);

  transition: opacity 2s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scroll-down.visible {
  opacity: 0.5;
}

.scroll-down.hidden {
  opacity: 0;
}
