
  
  .testimonials-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    width: 100%;
  }
  
  .testimonial-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
 
    width: 25%;
    cursor: pointer;
    transition: transform .2s;
  }
  
  .testimonial-card:hover {
    transform: scale(1.05);
  }
  
  .testimonial-header {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #ddd;
  }
  
  .testimonial-picture {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }
  
  .testimonial-name {
    font-size: 1.2em;
    margin: 0;
  }
  
  .testimonial-body {
    padding: 15px;
  }
  
  .testimonial-blurb {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .testimonial-full {
    margin: 0;
    transition: transorm 1s;
  }
  
  @media (max-width: 768px) {
    .testimonial-card {
      width: 100%;
      margin: 10px 0;
    }
  }
  