body {
  line-height: 1.6;
  font-size: 16px;
  font-weight: 400;
  color: #4d4d4d;
}

li {
  padding-bottom: 1rem;
}

@import url("https://fonts.googleapis.com/css2?family=Faster+One&family=Oswald:wght@200..700&family=Playwrite+NG+Modern:wght@100..400&display=swap");
.homeSplash {
  display: flex;
  margin-top: 7rem;
  justify-content: space-around;
  align-items: center;
  height: 44rem;
  object-fit: fill;
}

.video1 {
  width: 100%;
  height: 100%;

  object-fit: cover;
}
.homeSplashContent {
  position: absolute;
  width: 100%;

  top: 0;

  padding: 22px;

  margin-top: 11rem;

  border-width: 8px;

  border-radius: 4px;
  display: flex;
  flex-direction: column;
  text-wrap: wrap;
  width: 66%;

  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #074f80;
  border-radius: 16px;
}

.homeSplashContentTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homeSplashContentBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homeSplashContent a {
  font-family: "Oswald", sans-serif;
  line-height: 1.6;
  font-size: 20px;
  font-weight: 400;
  font-weight: 400;
  font-style: normal;
  font-size: 19px;
  font-weight: 500;
  width: 60%;
  text-align: center;
  padding-top: 2rem;

  text-transform: uppercase;
  text-shadow: 2px 2px 16px rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.71);
}

.homeSplashContent h1 {
  color: rgba(255, 255, 255, 0.71);
  font-family: "Oswald", system-ui;
  font-weight: 200;
  font-style: light;

  text-transform: uppercase;

  margin-bottom: 0px;
  font-size: 7rem;
  padding: none;
  margin: 0px 0px -5px 0px;
  text-shadow: 2px 2px 16px rgba(255, 255, 255, 0.71);
}

.homeSplashContent h2 {
  font-family: "Oswald", sans-serif;
  color: rgba(255, 255, 255, 0.9);

  font-weight: 300;
  font-style: normal;
  font-size: 3rem;
  padding: none;
  margin: 0px 0px -5px 0px;
  text-transform: uppercase;
  text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.71);
}

.homeSplashContent h3 {
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 4rem;

  padding: none;
  margin: 0px 0px -5px 0px;
  text-transform: uppercase;
  text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.71);
  color: rgba(255, 255, 255, 0.6);
}

.applyButton {
  background-color: #074f80;
  border-radius: 4px;

  margin-bottom: 1rem;
  width: 12rem;
  height: 4rem;
  font-family: "Oswald", sans-serif;
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase;
  font-size: 1.5rem;
  color: rgba(136, 207, 242, 0.7);
  border: solid rgba(7, 79, 128, 0.5) 3px;

  transition: 0.3s;
}

.applyButton:hover {
  color: #074f80;
  background-color: rgba(136, 207, 242, 0.7);
  border: solid rgba(7, 79, 128, 0.5) 3px;
  transition: 0.3s;
  text-shadow: -1px -1px 10px rgba(360, 360, 360, 0.3);
}

.hypeVideo {
  width: 22rem;
  border: solid rgba(360, 360, 360, 0.4) 4px;
  object-fit: cover;
}

.agentFeedback {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-radius: 8px;
}

.agentFeedbackContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-bottom: 7rem;
  padding-top: 7rem;
}

.homePageDifferent {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  background: url(/src/components/images/differentSection.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
}

.homePageDifferentContainer {
  padding-top: 50px;
  padding-right: 20px;
  padding-bottom: 50px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;

  align-items: center;
  width: 90%;
}

.homePageDifferentTop {
  width: 70%;
  justify-content: center;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 4rem;
}

.homePageDifferentTop h1 {
  font-size: 4rem;
  font-family: "Oswald", sans-serif;
}

.homePageDifferentTop a {
  font-size: 1.4rem;
  font-family: "Oswald", sans-serif;
}

.readButton {
  background-color: rgba(136, 207, 242, 0.7);
  border-radius: 4px;

  margin-bottom: 1rem;
  width: 12rem;
  height: 4rem;
  font-family: "Oswald", sans-serif;
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase;
  font-size: 1.5rem;
  color: #074f80;
  border: solid rgba(7, 79, 128, 0.5) 3px;
  text-shadow: -1px -1px 10px rgba(360, 360, 360, 0.3);
}

.homePageDifferentBottom {
  margin-right: 0px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

.agentFeedbackContainer h1 {
  font-size: 4rem;
  font-family: "Oswald", sans-serif;
}

.mcInfo {
  font-family: "Oswald", sans-serif;
}

.mcInfoTop {
  display: flex;
  background: url(/src/components/images/mcInfoRight.webp);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: top right;
  width: 100%;
  justify-content: center;
}

.mcInfoTopLeft {
  width: 40%;
  justify-content: center;
  gap: 1rem;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
}

.leadsImage {
}

.mcInfoTopRight {
  width: 40%;
  justify-content: center;
  gap: 1rem;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
}

.mcInfoMiddle {
  display: flex;
  justify-content: center;
  background: url(/src/components/images/mcInfoLeft.webp);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: top left;
  width: 100%;
}

.mcInfoMiddleLeft {
  width: 40%;
  justify-content: center;
  gap: 1rem;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
}

#infoPics {
  height: auto;
  width: 100%;
}

.productImage {
}

.mcInfoMiddleRight {
  width: 40%;
  justify-content: center;
  gap: 1rem;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
}

.mcInfoBottom {
  display: flex;
  justify-content: center;
  background: url(/src/components/images/mcInfoRight.webp);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: top right;
  width: 100%;
}

.mcInfoBottomLeft {
  width: 40%;
  justify-content: center;
  gap: 1rem;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
}

.mcInfoBottomRight {
  width: 40%;
  justify-content: center;
  gap: 1rem;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
}

.founderContainer {
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(/src/components/images/founderBackground.webp);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  color: white;
}

.founderWrap {
  display: flex;
  justify-content: center;
  width: 80%;
}

.founderLeft {
  width: 40%;
  justify-content: center;
  gap: 1rem;
  padding-top: 20px;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
  height: 90%;
}

.founderRight {
  display: flex;
  width: 40%;
  gap: 1rem;
  padding-top: 20px;
  align-items: flex-end;
}

.presidentPhoto {
  width: 26rem;
}

.applyNowContainer {
  font-family: "Oswald", sans-serif;

  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.applyNowContent h1 {
  font-size: 2.5rem;
  margin: -1rem;
  padding: none;
}

.applyNowContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1080px) {
  .homeSplash {
    margin-top: none;
    height: 70vh;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    overflow: auto;
  }

  @media screen and (orientation: landscape) {
    .homeSplash {
      height: 110vh;
    }
  }

  #infoPics {
    height: auto;
    padding: 0;
  }

  .presidentPhoto {
    width: 22rem;
    padding: none;
    margin: none;
  }

  .homeSplashContent {
    width: 100%;
    padding: 0;
  }

  .homeSplashContent a {
    font-size: 1rem;
    width: 90%;
    color: white;
    animation-name: fade-in;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;

    /* shorthand
		animation: fade-in 2s linear 0s 1 normal none;*/
  }

  .homeSplashContent h1 {
    font-size: 52px;
    color: white;
    margin-bottom: 0.5vh;
    animation-name: scale-in-ver-center;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;

    /* shorthand
		animation: scale-in-ver-center 0.5s linear 0.5s 1 normal none;*/
  }
  .homeSplashContent h2 {
    font-size: 30px;
    font-weight: 400;
    text-shadow: none;
    color: white;
    width: 80%;
    text-align: center;
    margin-bottom: 0.5vh;

    animation-name: focus-in-contract;
    animation-duration: 0.4s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;

    /* shorthand
		animation: focus-in-contract 0.5s linear 0s 1 normal none;*/
  }
  .homeSplashContent h3 {
    font-size: 33px;
    text-shadow: none;
    color: white;
    animation-name: fade-in;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;
    /* shorthand
		animation: fade-in 2s linear 0s 1 normal none;*/
  }

  .homeSplashContentBottom button {
    margin-top: 5vh;
  }

  .homePageDifferentContainer {
    margin-top: -5rem;
  }

  .homePageDifferentTop {
    width: 100%;
  }

  .homePageDifferentTop h1 {
    font-size: 44px;
  }

  .homePageDifferentTop a {
    font-size: 18px;

    width: 100%;
  }

  .agentFeedbackContainer {
    padding-top: 0;
    padding-bottom: 0;
  }

  .agentFeedbackContainer h1 {
    font-size: 42px;
    text-align: center;
  }

  .mcInfoTop {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mcInfoTopLeft {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 0;
    padding-left: 0;
  }

  .mcInfoTopRight {
    width: 100%;
    display: flex;
    align-items: center;
    padding-right: 0;
    padding-left: 0;
  }

  .mcInfoMiddle {
    flex-direction: column-reverse;

    justify-content: center;
    align-items: center;
  }

  .mcInfoMiddleLeft {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 0;
    padding-left: 0;
  }

  .mcInfoMiddleRight {
    width: 100%;
    display: flex;
    align-items: center;
    padding-right: 0;
    padding-left: 0;
  }

  .mcInfoBottom {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mcInfoBottomLeft {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 0;
    padding-left: 0;
  }

  .mcInfoBottomRight {
    width: 100%;
    display: flex;
    align-items: center;
    padding-right: 0;
    padding-left: 0;
  }

  .founderLeft {
    width: 100%;
  }
  .founderRight {
  }

  .applyNowContent {
    width: 66%;
  }
  .applyNowContainer {
    text-align: center;
  }
}

@media (max-width: 888px) {
  .presidentPhoto {
    width: 18rem;
    padding: none;
    margin: none;
  }

  .founderWrap {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .founderRight {
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
}

@media (max-width: 400px) {
  .homeSplashContent a {
    padding-top: 0;
  }
  .homeSplashContent h3 {
    padding-bottom: 1rem;
  }
}

@keyframes focus-in-contract {
  0% {
    letter-spacing: 0.1em;
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    filter: blur(0);
    opacity: 1;
  }
}

@keyframes scale-in-ver-center {
  0% {
    transform: scaleY(0);
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
