.divider {
  margin-top: 11rem;
  width: 70%;
}

button {
  background-color: #074f80;
  border-radius: 4px;

  margin-bottom: 1rem;
  width: 12rem;
  height: 4rem;
  font-family: "Oswald", sans-serif;
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase;
  font-size: 1.5rem;
  color: rgba(136, 207, 242, 0.7);
  border: solid rgba(7, 79, 128, 0.5) 3px;
  transition: 0.3s;
  margin-bottom: 7rem;
}

button:hover {
  color: #074f80;
  background-color: rgba(136, 207, 242, 0.7);
  border: solid rgba(7, 79, 128, 0.5) 3px;
  transition: 0.3s;
  text-shadow: -1px -1px 10px rgba(360, 360, 360, 0.3);
}

.growthMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.growthSplash {
  background-image: url("../images/windowBackground.webp");

  padding-top: 11rem;
  padding-bottom: 7rem;
  color: white;

  font-family: "Oswald", sans-serif;
  width: 100%;
}
.growthSplashContainer {
  padding-left: 11rem;
  width: 70%;
}

.growthSplashText {
}

.growthSplash h3 {
  font-family: "Oswald", sans-serif;

  font-weight: 600;
  font-style: normal;
  font-size: 2rem;
}

.growthSplash h2 {
  font-family: "Oswald", sans-serif;

  font-weight: 200;
  font-style: normal;
  font-size: 2rem;
}

.growtSplashText h1 {
  font-family: "Oswald", sans-serif;

  font-weight: 600;
  font-style: normal;
  font-size: 2rem;
}

.growtSplashText h2 {
  font-weight: 200;
  font-style: normal;
}

.growthInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.growthInfoTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Oswald", sans-serif;
  background-color: #074f80;
  color: #ffffff;
  padding-bottom: 7rem;
}

#headingStyle {
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 3rem;
  text-transform: uppercase;
  text-align: center;
}

.growthInfoTopWrapper {
  display: flex;
  justify-content: space-around;
}

.growthInfoTopLeft {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  font-size: large;
}

.growthInfoTopRight {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  font-size: large;
}

.growthInfoMiddleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Oswald", sans-serif;
}

.growthInfoMiddle {
  display: flex;
  justify-content: center;
}

.medaM {
  width: 20%;
}

#logoTitle {
  display: flex;
  justify-content: center;
}

#attributesText {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
}

#attributesText a {
  font-size: large;
  margin-left: 10px;
  text-align: center;
}

.growthInfoMiddleLeft {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 7rem;
  width: 90%;
}

.growthInfoMiddleRight {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.growthInfoMiddleRight h1 {
  margin-bottom: 0;
}

.pittmanRegion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 2vh;
}

.rezkoRegion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 2vh;
}

.rrContent {
  background-color: #074f80;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 7rem;
  text-decoration: none;
}

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}

.rrContent h1 {
  margin-top: 0;
}

.regionIG {
  height: 2rem;
  margin-left: 1rem;
}
.dannyD {
  width: 40vw;

  border-radius: 4px;
}

.growthMiddleWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.growthInfoBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.growthInfoBottom h3 {
  margin-top: 0;
}

@media (max-width: 768px) {
  .growthSplash {
    object-fit: cover;
    margin-top: 5vh;
    padding-top: 7rem;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .growthSplashContainer {
    padding-left: 0rem;
    width: 100%;
  }

  #headingStyle {
    font-size: 44px;
  }

  .growthInfoTopWrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .growthInfoTopLeft {
    width: 85%;
    text-align: center;
  }
  .growthInfoTopRight {
    width: 85%;
    text-align: center;
  }

  .growthInfoMiddle {
  }

  .growthInfoMiddleLeft {
    flex-direction: column;
  }

  #attributesText {
    width: 85%;
    border-top: lightblue solid 2px;

    margin-bottom: 2rem;
    padding-bottom: 1rem;
  }

  .growthInfoMiddleRight {
    flex-direction: column;
  }

  .dannyD {
    width: 100%;
  }
}

@media (max-width: 444px) {
  .growthHeading {
    width: 70%;
  }

  .growthInfoMiddleLeft {
    padding-bottom: 0;
  }

  .rrContent {
    margin-bottom: 2rem;
  }

  .growthInfoBottom {
    width: 70%;
    text-align: center;
  }
}
