@import url('https://fonts.googleapis.com/css2?family=Faster+One&family=Oswald:wght@200..700&family=Playwrite+NG+Modern:wght@100..400&display=swap');


.contactSplash{
    background: url(../images/medaContact.webp);
    background-size: cover;
    background-repeat: repeat;
    width: 100%;
    height: 13rem;
    margin-top: 7rem;
   margin-bottom: 7rem;
   padding-top: 2rem;
   padding-bottom: 2rem;
    display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
    color: white;
}

.contactSplash h1{
    padding: none;
    margin: 0;
    font-family: "Oswald", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3rem;
}

.contactBottom{
    margin-bottom: 7rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;    display: flex;

}

.contactBottomRight{
width: 40%;
display: flex;
justify-content: center;
align-items: center;
height: 100%;
border-radius: 4px;

}

    
.contactBottomLeft{
    width: 40%;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.hypeVid{
    width: 22rem;
    border-radius: 4px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.contactBottomInfo{
    display: flex;
    justify-content: center;
    padding-bottom: 7rem;
    font-family: "Oswald", sans-serif;
}

.contactUs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #074f80;
    color: white;
    padding-left: 3rem;
    padding-right: 3rem;
    border-radius: 4px;
}

@media (max-width: 768px){
    .contactBottom{
        flex-direction: column;
    }

    .contactBottomLeft{
        width: 90%;
    }

    .contactSplash{
        display: flex;
        flex-direction: column;
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

     h1{
        font-family: "Oswald", sans-serif;
        font-weight: 600;
        font-style: normal;
        font-size: 2rem;
        text-align: center;
    }

    .contactUs{
        padding-left: 0;
        padding-right: 0;
    }
}