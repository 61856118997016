@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext");

body {
  line-height: 1.6;
  font-size: 16px;
  font-weight: 400;
}

.MenuBar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-top: 1rem;

  position: fixed;
  top: 0;
  transition: height 0.3s, transform 0.3s;
  z-index: 1000;
  background-color: white;
  width: 100vw;
}

.MenuBarRightContent a {
  text-decoration: none;
  color: #074f80;
  cursor: pointer;
  font-weight: 800;
  font-family: "Oswald", sans-serif;
}

.FooterMiddle a {
  text-decoration: none;
  color: white;
  cursor: pointer;
  font-weight: 800;
  font-family: "Oswald", sans-serif;
}

.MenuBar.original-size {
  height: 100px; /* Adjust to your original height */
}

/* Collapsed size */
.MenuBar.collapsed {
  height: 60px; /* 30% of the original height */
  transform: translateY(-2px); /* Adjust to collapse smoothly */
  padding: 5px;
  background-color: rgba(300, 300, 300, 0.5);
}

/* Adjusting the menu logo size */
.MenuBar .MenuLogo {
  transition: height 0.3s;
}

/* Collapsed logo size */
.MenuBar.collapsed .MenuLogo {
  height: 60px; /* Adjust to 30% of the original height */
}

.MenuBarLeft {
  padding-left: 7rem;
}

.MenuLogo {
  width: 10rem;
  height: 7rem;
  aspect-ratio: 118/75;
}

.MenuBarRight {
  padding-right: 6rem;
  display: flex;
  align-items: center;
}

.MenuBarRightContent a {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-bottom: 0.3rem;
  font-family: "Oswald", sans-serif;
  font-weight: 444;
  font-style: normal;
}

.menu-item:hover {
  font-weight: bold;
  padding: 2px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.Footer {
  background-color: #074f80;
  display: flex;
  flex-direction: column;

  align-items: center;
  background: url(/src/components/images/FooterBackground.webp);
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  background-position: top center;
  background-color: #074f80;
}

.FooterContent {
  padding-top: 4rem;
  padding-bottom: 4rem;
  max-width: 1120px;
}

.FooterTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FooterTop hr {
  height: 2px;
  width: 40vw;
  background-color: #ffffff;
}

.FooterSurfer {
  width: 16rem;
  height: 10rem;
  aspect-ratio: 118 / 75;
}

.FooterMiddle {
  padding-top: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

.FooterMiddle a {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-bottom: 0.3rem;
}

.FooterBottom {
  padding-top: 1rem;
}

.FooterSocial {
  display: flex;
  justify-content: center;
  align-items: center;
}

.socialIcon {
  height: 50px;
  width: 50px;
  padding-left: 22px;
  padding-right: 22px;
}

.copyBar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #074f80;
  align-items: center;
  margin: none;
  width: 100%;

  padding-bottom: 1rem;
}

@media (max-width: 1080px) {
  .MenuBarRightContent {
    display: none;
  }

  .mobile-menu-icon {
    display: block;
  }

  .MobileMenu {
    display: flex;
    flex-direction: column;
    top: 0;
    left: 10;
  }

  .MenuLogo {
    height: 5rem;
  }

  .MenuBar.collapsed {
    height: 6vh; /* 30% of the original height */
    transform: translateY(-2px); /* Adjust to collapse smoothly */
    padding: 5px;
    padding-bottom: 1rem;
    background-color: rgba(300, 300, 300, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MenuBar.collapsed .MenuLogo {
    height: 52px;
    margin-top: 1rem;
  }

  .MenuBar {
    width: 100vw;
  }

  .MenuBarLeft {
    height: auto;
    width: 50%;
    margin-left: -10%;
  }

  .MenuBarRight {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .MobileMenu {
    border-radius: 4px;
    background-image: linear-gradient(
      to right bottom,
      rgba(300, 300, 300, 0.9),
      rgba(300, 300, 300, 1)
    );

    position: absolute;

    left: 0%;
    height: 100vh;

    padding: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: solid rgba(136, 207, 242, 0.7) 3px;
    border-bottom: solid rgba(136, 207, 242, 0.7) 3px;
    border-radius: 4px;
    transition: 0.2s;
  }

  .MobileMenu a {
    color: #074f80;
    text-decoration: none;
    font-family: "Oswald", sans-serif;
    font-size: 44px;
  }

  .mobile-menu-icon {
    font-size: 44px;
    vertical-align: middle;
    color: #074f80;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }

  .copyBar {
    font-family: "Oswald", sans-serif;
    display: flex;
    flex-direction: column;
  }

  .menuIconContainer {
    margin-top: 1rem;
    background-color: #074f80;
    width: 100%;
    padding-top: 22px;
    padding-bottom: 22px;
    display: flex;
    justify-content: center;
    border-radius: 8px;
  }
  .menuSocialIcon {
    margin-left: 11px;
    margin-right: 11px;
  }
  #layoutApplyButton {
    margin-bottom: 0;
    color: white;
    border-radius: 8px;
  }
}

@media (max-width: 444px) {
  .MenuBarLeft {
    padding-left: 3rem;
  }

  .MenuBarRight {
    padding-right: 3rem;
  }
}

@media (min-width: 1081px) {
  .mobile-menu-icon {
    display: none;
  }

  .MenuBarRightContent {
    display: flex;
  }
}
